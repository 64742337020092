import React from 'react';
import { motion } from 'framer-motion';
import logoSvg from '../assets/customcast/custom_cast_doAI_animated.svg';

const AnimatedLogo = ({ onComplete }) => {
  return (
    <motion.div
      initial={{ 
        opacity: 0,
        y: 0
      }}
      animate={{ 
        opacity: 1,
        y: 0
      }}
      transition={{ 
        duration: 2,
        ease: "easeOut",
      }}
      onAnimationComplete={onComplete}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem',
        paddingTop: '1rem'
      }}
    >
      <motion.img
        src={logoSvg}
        alt="Logo"
        style={{
          width: '50vw',
          maxWidth: '600px',
          height: 'auto',
          minWidth: '200px'
        }}
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        transition={{ 
          duration: 1.5,
          delay: 0.3
        }}
      />
    </motion.div>
  );
};

export default AnimatedLogo;