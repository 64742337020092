import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { trackEvent, logArticleClick } from './utils/analytics';
import { Box, IconButton, Typography, List, ListItem, Paper, Slider, ListItemText, Link, Stack, Button, Collapse } from '@mui/material';
import { ReactComponent as Logo } from './assets/customcast/White logo - no background.svg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatCategoryList } from './utils';



const AudioPlayer = ({ audioFileUrl, articles = [], categories = null, lastUsedDate = new Date(), keywords = null }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);
    const [showArticles, setShowArticles] = useState(false);

    // Move updateProgress definition before its usage
    const updateProgress = useCallback(() => {
        const audio = audioRef.current;
        if (audio) {
        const currentTime = audio.currentTime;
        setProgress((currentTime / audio.duration) * 100);
        }
    }, []);

    // 1. First useEffect - Audio player setup
    useEffect(() => {
        if (audioRef.current) {
        const audio = audioRef.current;

        const handleTimeUpdate = () => {
            setProgress(audio.currentTime);
        };

        const handleLoadedMetadata = () => {
            setDuration(audio.duration);
        };

        const handleEnded = () => {
            trackEvent('audio_complete', {
            player_type: 'audio_player',
            duration: duration
            });
            setIsAudioPlaying(false);
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('ended', handleEnded);
        };
        }
    }, [duration]);

    // 2. Second useEffect - Data validation and navigation
    useEffect(() => {
        if (!audioRef) {
        console.error('Missing required data for Audio Player');
        navigate('/');
        return;
        }

        const audio = audioRef.current;
        if (audio) {
        const handleMetadata = () => setDuration(audio.duration);
        audio.addEventListener('loadedmetadata', handleMetadata);
        audio.addEventListener('timeupdate', updateProgress);

        return () => {
            audio.removeEventListener('loadedmetadata', handleMetadata);
            audio.removeEventListener('timeupdate', updateProgress);
        };
        }
    }, [navigate, updateProgress]);

    // Now we can have our conditional return
    if (!audioRef) {
        return null;
    }

    const handleSkip = (seconds) => {
        const audio = audioRef.current;
        if (audio) {
        const newTime = Math.min(Math.max(0, audio.currentTime + seconds), audio.duration);
        audio.currentTime = newTime;
        
        trackEvent('audio', {
            action: 'skip',
            direction: seconds > 0 ? 'forward' : 'backward',
            skip_amount: Math.abs(seconds)
        });
        }
    };

    const togglePlay = () => {
        const audio = audioRef.current;
        if (audio) {
        if (isAudioPlaying) {
            audio.pause();
            trackEvent('audio', {
            action: 'pause',
            current_time: audio.currentTime,
            duration: audio.duration
            });
        } else {
            audio.play();
            trackEvent('audio', {
            action: 'play',
            current_time: audio.currentTime,
            duration: audio.duration
            });
        }
        setIsAudioPlaying(!isAudioPlaying);
        }
    };

    // Format time for display (convert seconds to MM:SS)
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSliderChange = (event, newValue) => {
        const audio = audioRef.current;
        if (audio) {
        const newTime = (newValue / 100) * audio.duration;
        audio.currentTime = newTime;
        setProgress(newValue);
        
        trackEvent('audio_progress_change', {
            new_position: newTime,
            duration: audio.duration
        });
        }
    };

    // Track feedback and demo clicks
    const handleFeedbackClick = () => {
        trackEvent('feedback_click', {
        location: 'play_summary',
        button_type: 'feedback'
        });
    };

    // Function to format the date
    const formatDate = (date) => {
        if (date != ''){
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
            const day = new Date(date).getDate();
            const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
            return formattedDate.replace(/\d+/, `${day}${suffix}`);
        } else {
            return date
        }

    };

    // Update article links
    const renderArticleList = () => (
        <List disablePadding>
        {articles.map((article, index) => (
            <ListItem 
            key={index}
            disableGutters
            sx={{
                borderBottom: index < articles.length - 1 ? '1px solid rgba(0, 0, 0, 0.08)' : 'none',
                py: 1.5
            }}
            >
            <ListItemText
                primary={
                <Link 
                    href={article.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={(e) => logArticleClick(article, index)}
                    onAuxClick={(e) => {  // Handle middle-click
                    if (e.button === 1) {  // Middle mouse button
                        logArticleClick(article, index);
                    }
                    }}
                    onContextMenu={(e) => {  // Handle right-click
                    logArticleClick(article, index);
                    }}
                    sx={{ 
                    color: '#632382',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                    }}
                >
                    {article.title}
                </Link>
                }
            />
            </ListItem>
        ))}
        </List>
    );

    return (
        <Box>
            {/* Player Header */}
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 3,
                gap: 2
            }}>
            <Paper
                elevation={2}
                sx={{
                width: 120,
                height: 120,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#632382',
                borderRadius: 2
                }}
            >
                <Logo style={{ width: 100, height: 'auto' }} />
            </Paper>
            <Box sx={{ flex: 1 }}>
                <Typography 
                variant="subtitle2" 
                sx={{ 
                    color: 'text.secondary',
                    mb: 0.5
                }}
                >
                NOW PLAYING
                </Typography>
                <Typography 
                variant="h6" 
                sx={{ 
                    fontWeight: 600,
                    mb: 0.5
                }}
                >
                Your News Summary for {formatDate(lastUsedDate)}
                </Typography>
                <Typography 
                variant="subtitle1" 
                sx={{ color: 'text.secondary' }}
                >
                {keywords && keywords !== '' ? `Here's your podcast about: ${keywords}` : categories && categories.length > 0 ? formatCategoryList(categories) : 'Check profile page for subscribed categories.'}
                </Typography>
            </Box>
            </Box>

            {/* Progress Bar */}
            <Box sx={{ mb: 2 }}>
            <Slider
                value={progress}
                onChange={handleSliderChange}
                max={duration}
                sx={{
                color: '#632382',
                height: 4,
                '& .MuiSlider-thumb': {
                    width: 12,
                    height: 12,
                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                    '&:hover, &.Mui-focusVisible': {
                    boxShadow: `0px 0px 0px 8px rgb(99 35 130 / 16%)`
                    },
                    '&.Mui-active': {
                    width: 16,
                    height: 16,
                    },
                },
                '& .MuiSlider-rail': {
                    opacity: 0.28,
                }
                }}
            />
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                px: 0.5
            }}>
                <Typography variant="caption" color="text.secondary">
                {formatTime(progress)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                {formatTime(duration)}
                </Typography>
            </Box>
            </Box>

            {/* Playback Controls */}
            <Stack 
            direction="row" 
            spacing={2} 
            justifyContent="center" 
            alignItems="center"
            sx={{ mb: 3 }}
            >
            <IconButton 
                onClick={() => handleSkip(-10)}
                sx={{ color: 'text.secondary' }}
            >
                <Replay10Icon />
            </IconButton>
            
            <IconButton 
                onClick={togglePlay}
                sx={{ 
                width: 56, 
                height: 56,
                backgroundColor: '#632382',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#44185a',
                },
                boxShadow: '0 4px 12px rgba(99, 35, 130, 0.4)'
                }}
            >
                {isAudioPlaying ? 
                <PauseIcon sx={{ fontSize: 32 }} /> : 
                <PlayArrowIcon sx={{ fontSize: 32 }} />
                }
            </IconButton>
            
            <IconButton 
                onClick={() => handleSkip(10)}
                sx={{ color: 'text.secondary' }}
            >
                <Forward10Icon />
            </IconButton>
            </Stack>
            {/* Articles Section */}
            <Box sx={{ mt: 2 }}>
                <Button
                  onClick={() => setShowArticles(!showArticles)}
                  endIcon={showArticles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  fullWidth
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'rgba(99, 35, 130, 0.08)'
                    }
                  }}
                >
                  {showArticles ? 'Hide Articles' : 'Show Articles'}
                </Button>

                <Collapse in={showArticles} timeout={500}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      mt: 2,
                      p: 2,
                      backgroundColor: 'rgba(0, 0, 0, 0.02)',
                      borderRadius: 2
                    }}
                  >
                    {renderArticleList()}
                  </Paper>
                </Collapse>
              </Box>
            {/* Audio Element */}
            <audio
                ref={audioRef}
                src={audioFileUrl}
                onEnded={() => setIsAudioPlaying(false)}
                style={{ display: 'none' }}
            />
        </Box>
    );
};

export default AudioPlayer; 