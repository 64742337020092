import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { Typography, Container, CircularProgress, Paper, Alert, Button, Chip, Box, Select, MenuItem, FormControl, InputLabel, Avatar } from '@mui/material';
import MenuBar from './components/MenuBar';
import { makeAuthenticatedRequest } from './utils/apiService';
import { cleanupAndFormatCategory } from './utils';
import countries from './utils/english_countries.json';

export const MAIN_CATEGORIES = [
  "National",
  "Science",
  "Sports",
  "Business",
  "Health",
  "Entertainment",
  "Tech",
  "Politics",
  "Food",
  "Travel"
];

const ProfilePage = () => {
  const { user } = useUser();
  const [userState, setUserState] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('nz');
  const [validationError, setValidationError] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user && user.uid) {
        try {
          setLoading(true);
          setError(null);
          setInfo(false);

          // Fetch user state
          const stateResponse = await makeAuthenticatedRequest(
            process.env.REACT_APP_USER_STATE_ENDPOINT,
            {
              method: 'GET'
            }
          );

          const stateData = await stateResponse.json();
          setUserState(stateData);

          // Fetch user preferences
          const preferencesResponse = await makeAuthenticatedRequest(
            process.env.REACT_APP_USER_PREFERENCES_ENDPOINT,
            {
              method: 'GET'
            }
          );

          if (preferencesResponse.status === 404) {
            setUserPreferences(null);
          } else {
            const preferencesData = await preferencesResponse.json();
            setUserPreferences(preferencesData);
            setSelectedCategories(preferencesData.categories)
            setSelectedCountry(preferencesData.country)
          }

        } catch (error) {
          console.error('Error fetching user info:', error);
          setError('Failed to fetch user information. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserInfo();
  }, [user]);

  const handleToggle = (category) => {
    const currentIndex = selectedCategories.indexOf(category);
    const newSelectedCategories = [...selectedCategories];

    if (currentIndex === -1) {
      newSelectedCategories.push(category);
    } else {
      newSelectedCategories.splice(currentIndex, 1);
    }

    setSelectedCategories(newSelectedCategories);
    setValidationError(null);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    // Optionally, save the selected country to user preferences or state
  };

  const handleSave = async () => {
    if ( userState.membershipTier == "Free" &&  selectedCategories.length > 3 ) {
      setValidationError('Please upgrade to select more than 3 categories.');
      return;
    }

    if (selectedCategories.length === 0 ) {
      setValidationError('Please select at least one category before submitting.');
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_USER_PREFERENCES_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify({
            categories: selectedCategories,
            sources: ['Stuff'],
            country: selectedCountry
          })
        }
      );
      
      if (!response.ok) {
        setValidationError("Error updating your preferences, please try again later.")
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setInfo(true)
      setValidationError("Preferences saved.")


      
      return response;
    } catch (error) {
      console.error('Error saving preferences:', error);
      throw error;
    }
  };

  const renderContent = () => (
    <Paper elevation={3} sx={{ padding: '20px', marginTop: '20px', borderRadius: 3 }}>
      {userState && (
        <Box display="flex" alignItems="center" mb={3} sx={{ padding: 2 }}>
          <Avatar 
            alt={user.displayName}
            src="/path/to/default/profile/picture.jpg" // Replace with the path to your default image
            sx={{ width: 80, height: 80, marginRight: 3 }} // Increased size of Avatar
          />
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{user.displayName || 'User Name'}</Typography> {/* Increased font size */}
            <Typography variant="body1" color="text.secondary"> {/* Changed to body1 for larger text */}
              Membership Tier: {userState.membershipTier || 'Free'}
            </Typography>
          </Box>
        </Box>
      )}
      
      {userPreferences ? (
        <>
          <Typography variant="h6" gutterBottom>
            Preferred Categories:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
            {MAIN_CATEGORIES.map((category) => (
              <Chip
                key={category}
                label={category}
                onClick={() => handleToggle(category.toLowerCase())}
                color={selectedCategories.includes(category.toLowerCase()) ? "primary" : "default"}
                variant={selectedCategories.includes(category.toLowerCase()) ? "filled" : "outlined"}
                clickable
                sx={{
                  '&:hover': {
                    backgroundColor: selectedCategories.includes(category) 
                      ? 'primary.dark' 
                      : 'action.hover',
                  },
                }}
              />
            ))}
          </Box>

          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="country-select-label">Select Country</InputLabel>
            <Select
              labelId="country-select-label"
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Select Country"
            >
              {Object.entries(countries).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="outlined" onClick={() => handleSave()} sx={{ mt: 4 }}>
            Save
          </Button>
          {validationError && (
            <Alert 
              severity= {info? "info" : "error"}
              sx={{ 
                width: '100%',
                maxWidth: 400,
                mt: 2,
                borderRadius: 2
              }}
            >
              {validationError}
            </Alert>
          )}
        </>
      ) : (
        <Typography variant="body1">
          No history of summaries available for this user.
        </Typography>
      )}
    </Paper>
  );

  return (
    <>
      <MenuBar />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          renderContent()
        )}
      </Container>
    </>
  );
};

export default ProfilePage;
