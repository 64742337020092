import React, { createContext, useState, useContext, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { auth } from "./firebase/firebaseConfig";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Initialize user with empty preferences if they don't exist
        setUser({
          ...user,
          preferences: user.preferences || {
            categories: [],
            sources: []
          }
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateUser = (userData) => {
    setUser(prevUser => {
      if (!prevUser) return userData;
      return {
        ...prevUser,
        ...userData,
        preferences: {
          ...(prevUser.preferences || {}),
          ...(userData.preferences || {})
        }
      };
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <UserContext.Provider value={{ user, loading, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
