import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Chip, Button, Container, CircularProgress, Alert, Collapse, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { cleanupAndFormatCategory, filterAndSortCategories } from './utils';
import LoadingScreen from './LoadingScreen';
import { useUser } from './UserContext';
import MenuBar from './components/MenuBar';
import AnimatedLogo from './components/AnimatedLogo';
import logoSvg from './assets/customcast/CustomCast_Powered_By_DoAi_rectangle.svg';
import { trackEvent } from './utils/analytics';
import ReactGA from 'react-ga4';
import { makeAuthenticatedRequest } from './utils/apiService';
import { auth } from './firebase/firebaseConfig';
import { debounce } from 'lodash';
import countries from './utils/english_countries.json'; // Import the country data

const TypeWriter = ({ text, delay, onComplete }) => {
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    let timeoutId;
    const typeNextChar = (index) => {
      if (index < text.length) {
        setCurrentText(text.slice(0, index + 1));
        timeoutId = setTimeout(() => typeNextChar(index + 1), delay);
      } else if (onComplete) {
        // Add a delay before calling onComplete
        timeoutId = setTimeout(onComplete, 1000); // 2 seconds delay
      }
    };

    typeNextChar(0);

    return () => clearTimeout(timeoutId);
  }, [text, delay, onComplete]);

  return <span>{currentText}</span>;
};

const Onboarding = ({ isDemoMode }) => {
  const navigate = useNavigate();
  const { user, updateUser } = useUser();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(isDemoMode ? false : true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isStretching, setIsStretching] = useState(false);
  const [showCategoryContent, setShowCategoryContent] = useState(false);
  const [showOtherCategories, setShowOtherCategories] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showDemoStart, setShowDemoStart] = useState(isDemoMode);
  const [selectedCountry, setSelectedCountry] = useState('nz'); // State for selected country
  const [showCountrySelection, setShowCountrySelection] = useState(false); // State to manage country selection visibility

  const MESSAGES = {
    demo: [
      "Welcome to the Customcast Demo!"
    ],
    regular: [
      "Let's get started creating your first Customcast module."
    ]
  };

  const CATEGORIES = {
    demo: [
      'politics',
      'business',
      'sport',
      'nz-news',
      'technology',
      'world-news'
    ],
    regular: [
      "national",
      "science",
      "sports",
      "business",
      "health",
      "entertainment",
      "tech",
      "politics",
      "food",
      "travel"
    ]
  }

  const mainCategories = CATEGORIES.regular;

  const messages = isDemoMode ? MESSAGES.demo : MESSAGES.regular;

  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    setAllCategories(filterAndSortCategories(mainCategories))
    setIsLoading(false);
  }, [isDemoMode]);

  useEffect(() => {
    if (!isDemoMode) {
      setAllCategories(filterAndSortCategories(mainCategories));
      setIsLoading(false);
    }
    if (isDemoMode && !showDemoStart) {
      fetchCategories();
    }
  }, [fetchCategories, isDemoMode, showDemoStart]);

  useEffect(() => {
    if (isDemoMode) {
      const img = new Image();
      img.src = logoSvg;
    }
  }, [isDemoMode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateUser = useCallback(
    debounce(
      (categories) => {
        if (user) {
          updateUser({
            ...user,
            preferences: {
              categories,
              sources: ['Stuff']
            }
          });
        }
      }, 
      1000
    ),
    [updateUser, user]
  );

  useEffect(() => {
    if (selectedCategories.length > 0 && user) {
      debouncedUpdateUser(selectedCategories);
    }
    
    return () => {
      debouncedUpdateUser.cancel();
    };
  }, [selectedCategories, debouncedUpdateUser, user]);

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => {
      const newSelection = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      
      trackEvent('category_selection', {
        selection_action: prev.includes(category) ? 'removed' : 'added',
        category_name: category,
        total_categories: newSelection.length,
        categories_list: newSelection.join(',')
      });

      return newSelection;
    });
    setValidationError(null);
  };

  const savePreferences = async (categories, country) => {
    try {
      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_USER_PREFERENCES_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify({
            categories: categories,
            sources: ['Stuff'],
            country: selectedCountry
          })
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.error('Error saving preferences:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (isDemoMode) {
      setSelectedCountry("nz");
    }
    if (selectedCategories.length === 0) {
      setValidationError('Please select at least one category before submitting.');
      return;
    }

    if (selectedCategories.length > 3) {
      setValidationError('Please upgrade to select more than three categories.');
      return;
    }

    if (!isDemoMode && selectedCountry.length === 0) {
      setValidationError('Please select a country to personalise your news.');
      return;
    }
    
    setValidationError(null);


    ReactGA.event('generation_start', {
      categories_selected: selectedCategories.join(','),
      total_categories: selectedCategories.length
    });

    try {
      setIsGeneratingSummary(true);
      // First save the user preferences
      if (!isDemoMode) {
        await savePreferences(selectedCategories, selectedCountry);
        // Update user context with new preferences
        updateUser({
          ...user,
          preferences: {
            categories: selectedCategories,
            sources: ['Stuff'],
            country: selectedCountry
          }
        });
      }

      // Then proceed with news summarization
      const currentUser = auth.currentUser;
      if (!currentUser?.uid && !isDemoMode) {
        throw new Error('Not authenticated');
      }

      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
        {
          method: 'POST',
          isDemoMode,
          body: JSON.stringify({
            user_id: currentUser?.uid || 'demo-user',
            categories: selectedCategories,
            sources: ['Stuff'],
            provider_id: 'NewsAPI',
            country: selectedCountry,
            membershipTier: isDemoMode ? 'demo' : null
          })
        }
      );

      const data = await response.json();
      setIsLoading(false);
      if (isDemoMode) {
        navigate('/play-summary', { 
          state: { 
            summarized_news: data.summarized_news, 
            audio_file_url: data.audio_file_url,
            articles: data.articles,
            timestamps: data.timestamps,
            isDemoMode,
            selectedCategories
          } 
        });
      } else {
        navigate('/');
      }

      

    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while processing your request.');
      setIsLoading(false);
      setIsGeneratingSummary(false);
    }
  };

  const handleMessageComplete = useCallback(() => {
    if (currentMessageIndex < messages.length - 1) {
      setTimeout(() => {
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
      }, 600);
    } else {
      // Start the stretching animation
      setTimeout(() => {
        setIsStretching(true);
        // After stretching, show the categories
        setTimeout(() => {
          setShowCategoryContent(true);
        }, 500); // Wait for stretch animation before showing content
      }, 600);
    }
  }, [currentMessageIndex, messages.length]);

  const handleLogoAnimationComplete = () => {
    setShowLogo(false);
    // Start the message typewriter after logo animation
    setCurrentMessageIndex(0);
  };

  const handleStartDemo = () => {
    trackEvent('journey', 'onboarding_start', 'demo');
    setShowDemoStart(false);
  };

  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);
    setValidationError(null);
  };

  if (isDemoMode && showDemoStart) {
    return (
      <Container 
        maxWidth="sm"
        sx={{ 
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          px: 2
        }}
      >
        <Box sx={{ 
          width: '100%',
          maxWidth: '600px',
          mb: 1
        }}>
          <img 
            src={logoSvg} 
            alt="Customcast Logo"
            style={{
              width: '100%',
              height: 'auto'
            }}
          />
        </Box>

        <Typography 
          variant="h4"
          sx={{ 
            fontWeight: 600,
            color: 'text.primary',
            textAlign: 'center',
            mb: 2
          }}
        >
          Create Your Custom News Podcast in Seconds
        </Typography>

        <Box sx={{ 
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            },
            '50%': {
              transform: 'scale(1.05)',
              boxShadow: '0 12px 40px rgba(0, 0, 0, 0.3)',
            },
            '100%': {
              transform: 'scale(1)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            },
          }
        }}>
          {/* Centering the Start Demo Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              variant="contained"
              onClick={handleStartDemo}
              sx={{
                py: 3,
                px: 6,
                fontSize: '1.5rem',
                borderRadius: 3,
                textTransform: 'none',
                backgroundColor: 'primary.main',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s ease',
                animation: 'pulse 2s infinite',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 12px 40px rgba(0, 0, 0, 0.3)',
                  backgroundColor: 'primary.dark',
                }
              }}
            >
              Start Demo
            </Button>
          </Box>

          {/* Link Text for Sign Up */}
          <Typography 
            variant="body1" 
            sx={{ 
              mt: 2, // Margin top for spacing
              textAlign: 'center',
              color: 'primary.main',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => navigate('/signup')} // Adjust the path to your sign-up page
          >
            Ready to dive in? Sign up now and start exploring!
          </Typography>
        </Box>
      </Container>
    );
  }

  if (isGeneratingSummary) {
    return (
      <Container 
        maxWidth="sm"
        sx={{ 
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoadingScreen 
          selectedSource="Stuff" 
          selectedCategories={selectedCategories}
          isDemoMode={isDemoMode}
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <>
        <MenuBar />
        <Container 
          maxWidth="sm"
          sx={{ 
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={40} />
          <Typography 
            variant="h6" 
            sx={{ mt: 2 }}
          >
            Loading...
          </Typography>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <MenuBar />
        <Container 
          maxWidth="sm"
          sx={{ 
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography 
            variant="h6" 
            color="error" 
            gutterBottom
          >
            {error}
          </Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      {!isDemoMode && <MenuBar />}
      <Container maxWidth="sm">
        {isDemoMode && <AnimatedLogo onComplete={handleLogoAnimationComplete} />}
        <Box sx={{ 
          opacity: isDemoMode ? (showLogo ? 0 : 1) : 1, 
          transition: 'opacity 1.2s' 
        }}>
          <Paper elevation={3} sx={{
            p: 4,
            borderRadius: 2,
            backgroundColor: 'background.paper',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            mt: isDemoMode ? 0 : 4 // Add top margin for regular users
          }}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography 
                variant="h4" 
                component="h1" 
                gutterBottom
                sx={{ 
                  fontWeight: 500,
                  color: 'primary.main'
                }}
              >
                {/* Show typewriter for demo, regular text for others */}
                {isDemoMode ? (
                  !showLogo && (
                    <TypeWriter 
                      text={messages[currentMessageIndex]} 
                      delay={50} 
                      onComplete={handleMessageComplete}
                    />
                  )
                ) : (
                  messages[currentMessageIndex]
                )}
              </Typography>
            </Box>

            {/* Modify the instructions text based on user type */}
            <Collapse in={isDemoMode ? isStretching : true} timeout={500}>
              <Box sx={{ mt: 2 }}>
                <Collapse in={isDemoMode ? showCategoryContent : true} timeout={800}>
                  <div>
                    {isDemoMode ? (
                      <>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            mb: 1, // Space between lines
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          We'll be sourcing from a range of NZ news outlets.
                        </Typography>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            mb: 2,
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          Select the categories that you're interested in:
                        </Typography>
                      </>
                    ) : (
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          mb: 2,
                          textAlign: 'center',
                          color: 'text.secondary'
                        }}
                      >
                        Select the categories that you want to include:
                      </Typography>
                    )}

                    {/* Categories Section */}
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mb: 4
                      }}
                    >
                      {/* Main Categories */}
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                        {mainCategories.filter(cat => allCategories.includes(cat)).map((category) => (
                          <Chip
                            key={category}
                            label={cleanupAndFormatCategory(category)}
                            onClick={() => handleCategoryToggle(category)}
                            color={selectedCategories.includes(category) ? "primary" : "default"}
                            variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                            clickable
                            sx={{
                              '&:hover': {
                                backgroundColor: selectedCategories.includes(category) 
                                  ? 'primary.dark' 
                                  : 'action.hover',
                              },
                            }}
                          />
                        ))}
                      </Box>

                      {/* Other Categories */}
                      {allCategories.some(cat => !mainCategories.includes(cat)) && (
                        <Box sx={{ textAlign: 'center' }}>
                          <Button 
                            onClick={() => setShowOtherCategories(!showOtherCategories)}
                            sx={{ mb: 1 }}
                          >
                            {showOtherCategories ? 'Hide Other Categories' : 'Show Other Categories'}
                          </Button>
                          <Collapse in={showOtherCategories}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                              {allCategories
                                .filter(cat => !mainCategories.includes(cat))
                                .map((category) => (
                                  <Chip
                                    key={category}
                                    label={cleanupAndFormatCategory(category)}
                                    onClick={() => handleCategoryToggle(category)}
                                    color={selectedCategories.includes(category) ? "primary" : "default"}
                                    variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                                    clickable
                                    sx={{
                                      '&:hover': {
                                        backgroundColor: selectedCategories.includes(category) 
                                          ? 'primary.dark' 
                                          : 'action.hover',
                                      },
                                    }}
                                  />
                              ))}
                            </Box>
                          </Collapse>
                        </Box>
                      )}
                      <Collapse in={showCountrySelection}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                          Please select your country:
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="country-select-label">Country</InputLabel>
                          <Select
                            labelId="country-select-label"
                            value={selectedCountry}
                            onChange={handleCountrySelect}
                            label="Country"
                          >
                            {Object.entries(countries).map(([code, name]) => (
                              <MenuItem key={code} value={code}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </Box>
                      </Collapse>
                    </Box>

                    {/* Continue Button for Category Selection */}
                    <Box 
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                      }}
                      >
                      <Button 
                        variant="contained" 
                        onClick={showCountrySelection || (!showCountrySelection && isDemoMode) ? handleSubmit : () => setShowCountrySelection(true)} // Handle submission for category selection
                        disabled={selectedCategories.length === 0} // Disable if no categories are selected
                        sx={{
                          py: 1.5,
                          px: 4,
                          fontSize: '1.1rem',
                          borderRadius: 2,
                          textTransform: 'none',
                          minWidth: 200
                        }}
                      >
                        Continue
                      </Button>
                      {validationError && (
                        <Alert 
                          severity="error" 
                          sx={{ 
                            width: '100%',
                            maxWidth: 400,
                            mt: 2,
                            borderRadius: 2
                          }}
                        >
                          {validationError}
                        </Alert>
                      )}
                    </Box>
                  </div>
                </Collapse>
              </Box>
            </Collapse>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export { Onboarding };
