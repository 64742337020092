import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleIcon } from './CustomIcons';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { makeAuthenticatedRequest } from './utils/apiService';
import logoSvg from './assets/customcast/Color_logo_no_background.svg';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function SignUp() {
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [signupError, setSignupError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.error) {
      setSignupError(location.state.error);
    }
  }, [location]);

  const validateInputs = () => {
    const nameInput = document.getElementById('name');
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    
    let isValid = true;

    if (!nameInput || !nameInput.value || nameInput.value.length < 1) {
      setNameError(true);
      setNameErrorMessage('Please enter your full name');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!emailInput || !emailInput.value || !/\S+@\S+\.\S+/.test(emailInput.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!passwordInput || !passwordInput.value || passwordInput.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  const handleGoogleSignUp = async () => {
    setSignupError('');
    setIsLoading(true);
    const provider = new GoogleAuthProvider();

    try {
      console.log('Starting Google signup...');
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log('Firebase auth successful, user:', user.uid);

      // Wait for a short delay to ensure Firebase auth state is updated
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Check if user is still authenticated
      const currentUser = auth.currentUser;
      console.log('Current user after delay:', currentUser?.uid);

      try {
        console.log('Making backend request...');
        const response = await makeAuthenticatedRequest(
          process.env.REACT_APP_USER_STATE_ENDPOINT,
          {
            method: 'POST',
            body: JSON.stringify({
              isAuthorized: true,
              membershipTier: 'Free'
            })
          }
        );

        console.log('Backend response:', response.status);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Navigating to onboarding...');
        // Try using state to pass auth status
        navigate('/onboarding', { 
          replace: true,
          state: { authenticated: true }
        });
      } catch (backendError) {
        console.error('Backend error:', backendError);
        throw new Error('Failed to initialize user in backend.');
      }
    } catch (error) {
      console.error('Error during Google sign-up:', error);
      setSignupError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const name = data.get('name');

    setSignupError(''); // Clear any previous errors

    try {
      // Create user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = userCredential.user;

      // Wait for a short delay to ensure Firebase auth state is updated
      await new Promise(resolve => setTimeout(resolve, 1000));

      const userData = {
        uid: newUser.uid,
        email: newUser.email,
        name: name,
        membershipTier: 'Free'
      };

      try {
        // Initialize user in backend
        const response = await makeAuthenticatedRequest(
          process.env.REACT_APP_USER_STATE_ENDPOINT,
          {
            method: 'POST',
            body: JSON.stringify({
              isAuthorized: true,
              membershipTier: userData.membershipTier
            })
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Both Firebase and backend calls succeeded
        navigate('/onboarding', { replace: true });
      } catch (backendError) {
        // Backend call failed, delete the user from Firebase
        await newUser.delete();
        console.error('Backend error:', backendError);
        throw new Error('Failed to initialize user in backend. Firebase user deleted.');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      if (error.code === 'auth/email-already-in-use') {
        setSignupError(
          <Typography>
            This email is not authorized for the beta programme. Please{' '}
            <Link 
              href="https://doai.co.nz/customcast" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              get in touch for access
            </Link>
            .
          </Typography>
        );
      } else {
        setSignupError('Unable to create account. Please try again later.');
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <SignUpContainer direction="column">
        <Card variant="outlined">
        <img 
            src={logoSvg} 
            alt="Customcast Logo"
            style={{
              width: '100%',
              height: 'auto'
            }}
          />

          <Typography
            component="h1"
            variant="body2"
            sx={{ 
              width: '100%', 
              fontSize: 'clamp(1.2rem, 4vw, 1.2rem)',
              textAlign: 'center'
            }}
          >
            Sign up now to get your news, your way!
          </Typography>

          {signupError && (
            <Typography color="error">
              {signupError}
            </Typography>
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="name">Full name</FormLabel>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                placeholder="Jon Snow"
                error={nameError}
                helperText={nameErrorMessage}
                color={nameError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="email"
                variant="outlined"
                error={emailError}
                helperText={emailErrorMessage}
                color={emailError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive updates via email."
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              Sign up
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <Link href="/login" variant="body2">
                Sign in
              </Link>
            </Typography>
          </Box>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>or</Typography>
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignUp}
              startIcon={<GoogleIcon />}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Sign up with Google'}
            </Button>
          </Box>
        </Card>
      </SignUpContainer>
    </>
  );
}
