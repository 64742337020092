export const cleanupAndFormatCategory = (category) => {
  // Replace slashes and dashes with spaces
  let cleaned = category.replace(/[/-]/g, ' ');
  
  // Split into words
  const words = cleaned.split(/\s+/);
  
  // Capitalize each word
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const filterAndSortCategories = (categories) => {
  return categories
    .filter(category => category.toLowerCase() !== 'quizzes')
    .sort((a, b) => cleanupAndFormatCategory(a).localeCompare(cleanupAndFormatCategory(b)));
};

export const formatCategoryList = (categories) => {
  const formattedCategories = categories.map(cat => cleanupAndFormatCategory(cat));
  
  if (formattedCategories.length === 1) {
    return formattedCategories[0];
  } else if (formattedCategories.length === 2) {
    return `${formattedCategories[0]} and ${formattedCategories[1]}`;
  } else {
    const lastCategory = formattedCategories[formattedCategories.length - 1];
    const otherCategories = formattedCategories.slice(0, -1).join(', ');
    return `${otherCategories}, and ${lastCategory}`;
  }
};
