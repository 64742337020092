import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

let demoCredentials = null;

export const getDemoCredentials = async () => {
  try {
    // If we already have credentials, return them
    if (demoCredentials) {
      return demoCredentials;
    }

    // Otherwise authenticate with Firebase
    const maxRetries = 3;
    const delay = 5000; // 5 seconds

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          process.env.REACT_APP_DEMO_USER_EMAIL,
          process.env.REACT_APP_DEMO_USER_PASSWORD
        );

        // Get both token and uid
        const token = await userCredential.user.getIdToken();
        const uid = userCredential.user.uid;

        // Store both credentials
        demoCredentials = { token, uid };
        return demoCredentials;
      } catch (error) {
        if (error.code === 'auth/too-many-requests') {
          console.log("Too many requests. Please try again later.");
          throw error; // Stop further attempts
        } else if (attempt < maxRetries - 1) {
          console.error("Login attempt failed:", error);
          await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retrying
        } else {
          console.error('Demo authentication failed after multiple attempts:', error);
          throw error; // Rethrow error after max retries
        }
      }
    }
  } catch (error) {
    console.error('Demo authentication failed:', error);
    throw error;
  }
};
