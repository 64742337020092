import { createTheme } from '@mui/material/styles';


export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#632382',
      light: '#ffffff',
    },
    secondary: {
      main: '#632382',
      light: '#822eaa',
      dark: '#44185a',
    },
    background: {
      default: '#e1eefd',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Signika',
    },
    h2: {
      fontFamily: 'Signika',
    },
    h3: {
      fontFamily: 'Signika',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
