import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { trackEvent } from './utils/analytics';
import { Card, CardContent, CircularProgress, Box } from '@mui/material';
import EmbeddedAudioPlayer from './EmbeddedAudioPlayer';
import { makeAuthenticatedRequest } from './utils/apiService';
import { getDemoCredentials } from './utils/demoAuthService';

const EmbeddedPlayer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [audioFileUrl, setAudioFileUrl] = useState('');
  const [previousSummaryArticles, setArticles] = useState([]);
  const [previousSummaryCategories, setPreviousSummaryCategories] = useState([]);
  const [lastUsedDate, setLastUsedDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Read userId from URL parameters
    const params = new URLSearchParams(location.search);
    const categoryFromParams = params.get('category');
    const appNameFromParams = params.get('appName');

    const fetchData = async () => {
      const demoCredentials = await getDemoCredentials();
      const userId = demoCredentials.uid;
      
      const response = await makeAuthenticatedRequest(
        process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
        {
          method: 'POST',
          isDemoMode: true,
          body: JSON.stringify({
            user_id: userId,
            function: "GenerateSummary",
            provider_id: "NZME",
            app_name: appNameFromParams,
            categories: [categoryFromParams]
          })
        }
      );

      const data = await response.json();
      setAudioFileUrl(data.audio_file_url);
      setArticles(data.articles);
      setPreviousSummaryCategories(data.categories);
      setLastUsedDate(data.last_used_date);
      setIsLoading(false);
    };

    fetchData();
  }, [location.search]);

  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        backgroundColor: '#ffffff', 
        width: '100%', // Make the container full width
        padding: 2 // Optional padding
      }}
    >
      <Card 
        sx={{ 
          width: '100%', // Responsive width
          margin: '0 auto',
          boxShadow: 0,
          borderRadius: 0,
          height: '100%', // Allow height to adjust based on content
          padding: 0
        }}
      >
        <CardContent sx={{ padding: 0, paddingBottom: '0 !important'}}>
          <EmbeddedAudioPlayer 
            audioFileUrl={audioFileUrl} 
            articles={previousSummaryArticles} 
            categories={previousSummaryCategories}
            lastUsedDate={lastUsedDate}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmbeddedPlayer;