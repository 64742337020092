import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { trackEvent } from './utils/analytics';
import { Box, IconButton, Typography, Slider, Stack, Button, Collapse, Paper} from '@mui/material';
import { ReactComponent as Logo } from './assets/customcast/White logo - no background.svg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const EmbeddedAudioPlayer = ({ audioFileUrl, articles = [], lastUsedDate = new Date(), isEmbedded = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);
    const [showArticles, setShowArticles] = useState(false);

    const updateProgress = useCallback(() => {
        const audio = audioRef.current;
        if (audio) {
            const currentTime = audio.currentTime;
            setProgress((currentTime / audio.duration) * 100);
        }
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            const audio = audioRef.current;

            const handleLoadedMetadata = () => {
                setDuration(audio.duration);
            };

            const handleEnded = () => {
                trackEvent('audio_complete', {
                    player_type: 'audio_player',
                    duration: duration
                });
                setIsAudioPlaying(false);
            };

            audio.addEventListener('loadedmetadata', handleLoadedMetadata);
            audio.addEventListener('ended', handleEnded);
            audio.addEventListener('timeupdate', updateProgress);

            return () => {
                audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
                audio.removeEventListener('ended', handleEnded);
                audio.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, [duration, updateProgress]);

    const handleSkip = (seconds) => {
        const audio = audioRef.current;
        if (audio) {
            const newTime = Math.min(Math.max(0, audio.currentTime + seconds), audio.duration);
            audio.currentTime = newTime;
            trackEvent('audio', {
                action: 'skip',
                direction: seconds > 0 ? 'forward' : 'backward',
                skip_amount: Math.abs(seconds)
            });
        }
    };

    const togglePlay = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isAudioPlaying) {
                audio.pause();
                trackEvent('audio', {
                    action: 'pause',
                    current_time: audio.currentTime,
                    duration: audio.duration
                });
            } else {
                audio.play();
                trackEvent('audio', {
                    action: 'play',
                    current_time: audio.currentTime,
                    duration: audio.duration
                });
            }
            setIsAudioPlaying(!isAudioPlaying);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSliderChange = (event, newValue) => {
        const audio = audioRef.current;
        if (audio) {
            const newTime = (newValue / 100) * audio.duration;
            audio.currentTime = newTime;
            setProgress(newValue);
            trackEvent('audio_progress_change', {
                new_position: newTime,
                duration: audio.duration
            });
        }
    };

    // Function to format the date
    const formatDate = () => {
        const today = new Date(); // Get today's date
        const targetDate = today; // Use provided date or today's date

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(targetDate);
        const day = targetDate.getDate();
        const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
        
        return formattedDate.replace(/\d+/, `${day}${suffix}`);
    };

    return (
        <Box sx={{ width: '100%', height: '100%', padding: 2, bgcolor: '#f0f0f0', borderRadius: 2 }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 3,
                gap: 2
            }}>
            <Paper
                elevation={2}
                sx={{
                width: 120,
                height: 120,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#632382',
                borderRadius: 2
                }}
            >
                <Logo style={{ width: 100, height: 'auto' }} />
            </Paper>
            <Box sx={{ flex: 1 }}>
                <Typography 
                variant="subtitle2" 
                sx={{ 
                    color: 'text.secondary',
                    mb: 0.5
                }}
                >
                NOW PLAYING
                </Typography>
                <Typography 
                variant="h6" 
                sx={{ 
                    fontWeight: 600,
                    mb: 0.5
                }}
                >
                Your News Summary for {formatDate()}
                </Typography>
                {/* <Typography 
                variant="subtitle1" 
                sx={{ color: 'text.secondary' }}
                >
                {categories && categories.length > 0 ? formatCategoryList(categories) : 'Check profile page for subscribed categories.'}
                </Typography> */}
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                    <IconButton onClick={() => handleSkip(-10)} sx={{ color: 'text.secondary' }}>
                        <Replay10Icon />
                    </IconButton>
                    <IconButton onClick={togglePlay} 
                                sx={{ width: 56, 
                                height: 56,
                                backgroundColor: '#632382',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#44185a',
                                },
                                boxShadow: '0 4px 12px rgba(99, 35, 130, 0.4)' }}>
                        {isAudioPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <IconButton onClick={() => handleSkip(10)} sx={{ color: 'text.secondary' }}>
                        <Forward10Icon />
                    </IconButton>
                
                    <Typography variant="caption" color="text.secondary" sx={{ width: '50px', textAlign: 'left' }}>
                        {progress > 0 ? formatTime((progress / 100) * duration) : '0:00'}
                    </Typography>
                    <Slider
                        value={progress}
                        onChange={handleSliderChange}
                        max={100}
                        sx={{
                            color: '#632382',
                            height: 4,
                            '& .MuiSlider-thumb': {
                                height: 12,
                                width: 12,
                                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0px 0px 0px 8px rgb(99 35 130 / 16%)`
                                },
                                '&.Mui-active': {
                                width: 16,
                                height: 16,
                                },
                            },
                            '& .MuiSlider-rail': {
                                opacity: 0.28,
                            }
                        }}
                    />
                    <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'right' }}>
                        {formatTime(duration)}
                    </Typography>
                </Stack>
            </Box>
            </Box>
            

            {/* <Button
                onClick={() => setShowArticles(!showArticles)}
                endIcon={showArticles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                fullWidth
                sx={{
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                    }
                }}
            >
                {showArticles ? 'Hide Articles' : 'Show Articles'}
            </Button>

            <Collapse in={showArticles} timeout={500}>
                <Box sx={{ mt: 1, p: 1, backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: 1 }}>
                    {articles.map((article, index) => (
                        <Typography key={index} variant="body2" sx={{ color: 'black' }}>
                            <a href={article.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>
                                {article.title}
                            </a>
                        </Typography>
                    ))}
                </Box>
            </Collapse> */}

            <audio ref={audioRef} src={audioFileUrl} onEnded={() => setIsAudioPlaying(false)} style={{ display: 'none' }} />
        </Box>
    );
};

export default EmbeddedAudioPlayer; 