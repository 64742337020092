import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { trackEvent } from './utils/analytics';
import { Typography, Button, Container, Grid, 
  Card, CardContent,Box,
  Chip, Paper, Alert, TextField
} from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import MenuBar from './components/MenuBar';
import LoadingScreen from './LoadingScreen';
import PlaySummary from './PlaySummary';
import { useUser } from './UserContext';
import AudioPlayer from './AudioPlayer';
import { makeAuthenticatedRequest } from './utils/apiService';

const Home = ({ isDemoMode }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedSource, setSelectedSource] = useState('Stuff');
  const [previousSummaryCategories, setPreviousSummaryCategories] = useState([]);
  const [previousSummaryArticles, setArticles] = useState([]);
  const [lastUsedDate, setLastUsedDate] = useState('');
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [audioFileUrl, setAudioFileUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [dailyCount, setDailyCount] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [userState, setUserState] = useState(null);
  const [previousKeyword, setPreviousKeyword] = useState(null);


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        getLatestSummary(user.uid)
        getFirstNameFromUser(user)
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const getLatestSummary = async (userId) => {
    const response = await makeAuthenticatedRequest(
      process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
      {
        method: 'POST',
        isDemoMode,
        body: JSON.stringify({
          user_id: user.uid,
          function: "RetrieveLastAudio"
        })
      }
    );

    const data = await response.json();
    setIsLoading(false);
    setAudioFileUrl(data.audio_file_url)
    setArticles(data.articles)
    setPreviousSummaryCategories(data.categories)
    setLastUsedDate(data.last_used_date)
    setDailyCount(data.daily_count)
  }

  const fetchUserInfo = useCallback(async (userId) => {
    try {
      // Fetch user state
      const stateResponse = await makeAuthenticatedRequest(
        process.env.REACT_APP_USER_STATE_ENDPOINT,
        {
          method: 'GET'
        }
      );

      const stateData = await stateResponse.json();
      setUserState(stateData);

      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_USER_PREFERENCES_ENDPOINT}`,
        {
          method: 'GET'
        }
      );
      
      if (response.status === 404) {
        setIsReturningUser(false);
      } else if (response.ok) {
        setIsReturningUser(true);
        const preferencesData = await response.json();
        setSelectedCategories(preferencesData.categories)
        setSelectedCountry(preferencesData.country)
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setIsReturningUser(false);
    }
  }, []);

  useEffect(() => {
    if (user && user.uid) {
      fetchUserInfo(user.uid);
    }
  }, [user, fetchUserInfo]);

  const getFirstNameFromUser = (user) => {
    if (user.displayName) {
      setFirstName(user.displayName.split(' ')[0]);
    } else if (user.email) {
      setFirstName(user.email.split('@')[0]);
    } else {
      setFirstName(null)
    }
  };

  const generateKeywordPodcast = async () => {
    if (dailyCount >= 2 && userState.membershipTier === 'Free') {
      setValidationError("You've reached the daily limit for your free tier. Please upgrade to a paid tier to continue generating podcasts.")
      return
    }

    setIsLoading(true);
    fetchUserInfo(user.uid);
    trackEvent('keyword_podcast', {
      keywords: keyword
    });

    try {
      const response = await makeAuthenticatedRequest(
          process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
          {
              method: 'POST',
              body: JSON.stringify({
                  user_id: user.uid,
                  keywords: keyword,
                  country: selectedCountry,
                  provider_id: 'Perplexity',
                  membershipTier: userState.membershipTier || 'Free'
              })
          }
      );

      const data = await response.json();
      
      setAudioFileUrl(data.audio_file_url);
      setArticles(data.articles);
    } catch (error) {
        console.error('Error regenerating podcast:', error);
    } finally {
        setIsLoading(false);
    }

  } 

  const regeneratePodcast = async () => {

    if (dailyCount >= 2 && userState.membershipTier === 'Free') {
      setValidationError("You've reached the daily limit for your free tier. Please upgrade to a paid tier to continue generating podcasts.")
      return
    }

    setIsLoading(true);
    fetchUserInfo(user.uid);
    
    
    trackEvent('regenerate_podcast', {
      categories_selected: selectedCategories,
      total_categories: selectedCategories.length
    });

    try {
      setPreviousKeyword('')
      const response = await makeAuthenticatedRequest(
          process.env.REACT_APP_SUMMARIZE_NEWS_ENDPOINT,
          {
              method: 'POST',
              body: JSON.stringify({
                  user_id: user.uid,
                  categories: selectedCategories,
                  sources: ['Stuff'],
                  country: selectedCountry,
                  provider_id: 'NewsAPI',
                  membershipTier: userState.membershipTier || 'Free'
              })
          }
      );

      const data = await response.json();
      
      setAudioFileUrl(data.audio_file_url);
      setArticles(data.articles);
      setPreviousSummaryCategories(data.categories);
      setLastUsedDate(data.last_used_date);
    } catch (error) {
        console.error('Error regenerating podcast:', error);
    } finally {
        setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingScreen selectedSource={selectedSource} selectedCategories={selectedCategories} />;
  }

  return (
    <>
      <MenuBar />
      <Container maxWidth="lg" sx={{ mt: 4, position: 'relative', height: '100vh' }}>
        {/* Welcome Banner */}
        <Typography 
          variant="h4" 
          sx={{ 
            textAlign: 'center', 
            mb: 0, 
            color: 'text.primary' 
          }}
        >
          {firstName ? `Hello, ${firstName}! 👋` : 'Hello! 👋'}
        </Typography>

        {/* Subtitle */}
        <Typography 
          variant="subtitle1" 
          sx={{ 
            textAlign: 'center', 
            mt: 1, // Margin-top to add space between the welcome message and subtitle
            mb: 4,
            color: 'text.secondary' 
          }}
        >
          Dive back into your last podcast, or regenerate a new one to stay up to date!
        </Typography>

        {/* Search Bar for Keywords */}
        {userState?.membershipTier === 'Admin' && (
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <TextField 
              label="Enter keywords" 
              variant="outlined" 
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              sx={{ 
                mr: 2, // Margin to the right
                backgroundColor: 'white', // Set background color to white
              }} 
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                generateKeywordPodcast();
                setPreviousKeyword(keyword);
                setKeyword('');
              }}
            >
              Generate Podcast
            </Button>
          </Box>
        )}

        {/* Card for AudioPlayer */}
        <Card 
          sx={{ 
            width: '70%', // Ensure it takes full width
            margin: '0 auto',
            boxShadow: 3,
            borderRadius: 4,
            mt: 4 // Add margin-top for spacing
          }}
        >
          <CardContent>
            <AudioPlayer 
              audioFileUrl={audioFileUrl} 
              articles={previousSummaryArticles} 
              categories={previousSummaryCategories}
              lastUsedDate={lastUsedDate}
              keywords={previousKeyword}
            />
          </CardContent>
        </Card>

        {/* Regenerate Podcast Button */}
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => regeneratePodcast()}
          >
            Regenerate Podcast
          </Button>
        </Box>

        {/* Validation Error Alert */}
        {validationError && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Alert 
              severity="error"
              sx={{ 
                width: '100%',
                maxWidth: 400,
                borderRadius: 2
              }}
            >
              {validationError}
            </Alert>
          </div>
        )}
      </Container>
    </>
  );
};

export default Home;
