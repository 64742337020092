import { logEvent as firebaseLogEvent } from 'firebase/analytics';
import ReactGA from 'react-ga4';
import { analytics } from '../firebase/firebaseConfig';

const isDev = process.env.NODE_ENV === 'development';
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const initGA = () => {
  try {
    if (measurementId) {
      window.dataLayer = window.dataLayer || [];
      
      ReactGA.initialize(measurementId, {
        debug: isDev
      });
      
      if (isDev) {
        console.log('Analytics initialized in development mode');
      }
    }
  } catch (error) {
    console.error('Analytics initialization error:', error);
  }
};

export const trackEvent = (eventName, params = {}) => {
  try {
    window.dataLayer?.push({
      'event': eventName,
      ...params,
      'timestamp': new Date().toISOString()
    });

    firebaseLogEvent(analytics, eventName, params);
    ReactGA.event(eventName, params);

    if (isDev) {
      console.log('Event tracked:', eventName);
    }
  } catch (error) {
    console.error('Tracking error:', error);
  }
};

export const logArticleClick = (article, index) => {
  const eventParams = {
    article_title: article.title,
    article_url: article.link,
    article_position: index + 1
  };

  firebaseLogEvent(analytics, 'article_click', eventParams);
  ReactGA.event('article_click', eventParams);
}; 